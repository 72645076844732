// "Common app styles"

canvas {
  width: 100% !important;
}

body {
  background-color: $gray-100;
  background-image: url(/assets/images/bg-glow.svg);
  background-repeat: no-repeat;
  background-position: top right;
  min-width: 23.438rem;
  font-weight: 400;
}

b, strong {
  font-weight: 700;
}

p {
  a {
    color: $secondary;
  }
}

.font-weight-medium {
  font-weight: 500;
}

.wrapper {
  position: relative;
  min-height: 43.75rem;
}

.skip-link {
  display: none;
}

#page_container {
  transition: $transition-base;
  z-index: 1;
  position: relative;
  @include media-breakpoint-up(lg) {
    padding-left: 18.063rem;
    .side-bar-inactive & {
      padding-left: 4.75rem;
    }
  }
}

.has-border-bottom {
  border-bottom: 1px solid $border-color;
}

.organization-display {
  background-color: $white;
  border-bottom: 1px solid $border-color;
  font-size: 1.25rem;
  display: block;
  padding: 0.625rem 1.875rem;
  border-radius: $border-radius $border-radius 0 0;
  text-transform: capitalize;
  margin-bottom: -5px;
  max-width: 1440px;
  margin: 0 auto;
}

.main-content-holder {
  padding: 1.25rem;
  background: rgba(255, 255, 255, 0.50);
  border: 0.063rem solid $white;
  box-shadow: $box-shadow;
  border-radius: $border-radius;
  max-width: 1440px;
  margin: 0 auto;
  @include media-breakpoint-up(md) {
    padding: 1.875rem;
  }
}

.dashboard-content-holder {
  max-width: 1440px;
  margin: 0 auto;
}

.main-content {
  position: relative;
  padding: 0.938rem;
  @include media-breakpoint-up(md) {
    padding: 1.875rem;
  }
  @include media-breakpoint-up(xl) {
    padding: 3.125rem;
  }
  .dashboard-content & {
    padding: 0.938rem 0.938rem;
    @include media-breakpoint-up(md) {
      padding: 1.25rem 1.875rem;
    }
    @include media-breakpoint-up(xl) {
      padding: 1.875rem 3.125rem;
    }
    &:after { 
      display: none
    }
  }
}

.profile-image-frame {
  @include size(9.375rem, 9.375rem);
  background: rgba(255, 255, 255, 0.50);
  border: 1px solid $border-color;
  box-shadow: $box-shadow;
  border-radius: $border-radius;
  overflow: hidden;

  img {
    @include size(100%);
    object-fit: cover;
  }
}

.profile-form {
  max-width: 56.25rem;
}

.sites-heading {
  @include media-breakpoint-down(lg) {
    width: 100%;
  }
}

.sites-head {
  @include d-flex(center, space-between, null, null);
  margin-bottom: 1.25rem;

  .sites-controls {
    @include d-flex(center, space-between, null, null);
    @include media-breakpoint-down(sm) {
      width: 100%;
      flex-direction: column-reverse;
      .sites-search-holder {
        width: 100%;
        margin-top: 1.25rem;;
      }
      .btn {
        width: 100%;
      }
    }
    .btn-icon {
      margin-left: 0.625rem;
      &.icon-selected {
        border-color: $secondary;
      }
    }
  }
}

.slide-card {
  border-radius: $border-radius;
  border: 1px solid $border-color;
  box-shadow: $box-shadow;
  @include size(auto, 100%);
  @include d-flex(center, space-between, null, null);
  background-color: $white;
  min-height: 8.063rem;
  padding: 1.25rem;
  transition: $transition-base;

  &:hover {
    cursor: pointer;
    border-color: $gray-400;
  }

  &.card-selected {
    border-color: $secondary;
    border-width: 0.125rem;
  }

  h6 {
    text-transform: capitalize;
    min-height: 2.5rem;
  }
}

.sites-slider {
  .slick-track {
    display: flex !important;
    margin-left: 0;
  }

  .slick-slide {
    padding: 0.25rem 0.625rem;
    height: inherit;

    &:first-child {
      .slide-card {
        @include d-flex(center, center, null, null);
        h6 {
          min-height: inherit;
          margin: 0;
        }
      }
    }
  }
}

.user-sites-detail {
  margin-bottom: 1.25rem;
  position: relative;
  min-height: 6.25rem;
}

.course-analytics-list {
  @extend %listreset;
  font-size: 0.875rem;
  li {
    @include d-flex(center, space-between, null, null);
    padding: 0.25rem 0;

    span {
      width: 70%;

      &:nth-child(even) {
        text-align: right;
        width: 30%;
      }
    }

    &.head-list-item {
      font-weight: 500;
      border-bottom: 0.063rem solid $border-color;
      padding-bottom: 0.625rem;
      span {
        width: 50%;
  
        &:nth-child(even) {
          width: 50%;
        }
      }
    }
  }
}

.list-group {
  .list-group-item {
    position: relative;

    svg {
      @include size(16px, 16px);
      @include position(absolute, 50%, 10px, null, null);
      transform: translateY(-50%);
    }
  }

  .pagination-dropdown {
    .card {
      .card-header {
        svg {
          width: 0.875em;
        }
      }
    }
  }
}

.stats-block,
.quick-stats {
  .number {
    display: inline-block;
    border: 0.063rem solid;
    padding: 0.188rem 0.438rem;
    font-size: 0.75rem;
    margin-left: 0.313rem;
    border-radius: 0.25rem;
    font-weight: 500;

    .svg-inline--fa {
      margin-right: 0.313rem;
    }

    &.success {
      color: $green;
      background: rgba($green, 0.1);
    }

    &.fails {
      color: $red;
      background: rgba($red, 0.1);
    }

    &.zero {
      color: $blue-light;
      background: rgba($blue-light, 0.1);
    }
  }
}

.full-md {
  @include media-breakpoint-down(md) {
    width: 100%;
  }
}

.page-content-header {
  @include d-flex(center, space-between, null, null);
  margin-bottom: 1.875rem;
  position: relative;
  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
}

.table-header {
  .page-content-header {
    @include media-breakpoint-down(md) {
      flex-direction: inherit;
    }
    @include media-breakpoint-down(xxl) {
      justify-content: start;
    }
  }
}

.no-data {
  padding: 1rem 0;
  display: inline-block;
}

.footer-note {
  @include d-flex(center, null, null, wrap row);
  font-size: 0.75rem;

  svg {
    @include size(1.5rem, 1.5rem);
    margin-right: 1.25rem;
    float: left;
  }

  span {
    width: calc(100% - 2.75rem);
    overflow: hidden;
  }
}

.navbar-nav {
  .user-filter-list {
    li {
      a {
        margin: 0;
        padding: 0 !important;
        .btn {
          padding-left: 0.313rem;
          padding-right: 0.313rem;
        }
      }
    }
  }
}

.user-filter-list {
  @extend %listreset;
  @include d-flex(center, null, null, null);
  @include media-breakpoint-down(xxl) {
    flex-direction: column;
  }

  >li {
    @include d-flex(center, null, null, null);
    list-style-type: none;
    color: $secondary;
    margin: 0 0.625rem;
    @include media-breakpoint-down(xxl) {
      margin: 0.625rem 0;
      width: 100%;
      .btn,
      .user-filters {
        width: 100%;
        text-align: left;
      }
      .dropdown-menu {
        .btn {
          text-align: center;
        }
      }
      >a {
        white-space: nowrap;
      }
      .nav-link {
        width: 100%;
        text-align: left;
      }
    }
    .btn {
      svg {
        margin-right: 0.625rem;
      }
    }
  }

  .text {
    font-size: 0.875rem;
  }

  >.list-group-item {
    .btn {
      padding: 0 1.875rem 0 0;

      svg {
        margin-left: 1.875rem;
      }
    }
  }
}

.user-filter-drop {
  .nav-link {
    &:after {
      display: none;
    }
  }
}

.user-content-head {
  @include media-breakpoint-down(xl) {
    padding-right: 3.75rem;
    position: relative;
    z-index: 2;
    width: 100%;
    padding-right: 0;
    justify-content: start;
    align-items: start;
    .sites-search-holder {
      margin-bottom: 0 !important;
    }
  }
}

.has-tip-info {
  position: relative;
  @include media-breakpoint-up(lg) {
    padding-left: 2.5rem; 
  }

  .form-check {
    @include media-breakpoint-down(lg) {
      display: inline-block;
    }
  }

  .tool-tip {
    margin-left: 0;
    @include media-breakpoint-up(lg) {
      @include position(absolute, 0, null, null, 0.75rem);  
    }
  }
}

.role-list {
  border-bottom: 0.063rem solid $border-color;
}

.themes-table {
  width: 100%;

  @include media-breakpoint-down(md) {
    display: flex;
    flex-flow: wrap row;
  }

  .theme-frame {
    border: 0.063rem solid $border-color;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    padding: 1.25rem;
    margin-top: -0.063rem;
    margin-bottom: 1.25rem;
    width: 100%;

    @include media-breakpoint-up(md) {
      display: flex;
      align-items: center;
    }

  }

  .theme-select {
    margin-bottom: 1.25rem;

    @include media-breakpoint-up(md) {
      width: 18.75rem;
      margin: 0 1.25rem 0 0;
      padding: 0 0 0 1.25rem;
    }
  }

  .theme-demos {
    @include media-breakpoint-up(md) {
      width: calc(100% - 20rem);
    }
  }
}

.learner-overview-header,
.lti-head {
  .sites-search-holder {
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }
}

.benefit-slider {
  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    padding: 0 0.938rem;
    height: inherit !important;
  }
}

.theme-card {
  font-size: 0.875rem;
  padding: 0 0.938rem;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;

  &.active {
    .tag-active {
      opacity: 1;
    }
  }

  .slick-slide {
    padding: 0 0.938rem;
  }

  .description {
    p {
      margin: 0;
    }
  }

  .tag-active {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    background: rgba(0, 128, 0, 0.8);
    color: #fff;
    padding: 0.625rem;
    opacity: 0;
    -webkit-transition: opacity 0.2s ease;
    -o-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
  }

  .image-block {
    position: relative;
    overflow: hidden;
    border: 1px solid $gray-400;
  }

  img {
    max-width: 100%;
    display: block;
    height: auto;
  }

  .title {
    margin-bottom: 0;
  }

  .slick-list {
    list-style: none;

    .theme-slide {
      overflow: hidden;
      position: relative;
      cursor: pointer;
      border: 1px solid $border-color;
      border-radius: $border-radius;
      box-shadow: $box-shadow;
      padding: 0.938rem;

      .title {
        font-size: 0.875rem;
        padding: 0.313rem 0;
      }

      .txt {
        -webkit-transition: transform 0.3s ease;
        -o-transition: transform 0.3s ease;
        transition: transform 0.3s ease;
      }

      .image-post {
        position: relative;

        &:hover {
          .ico-view {
            opacity: 1;
          }
        }

        .ico-view {
          @include size(1.875rem, 1.875rem);
          @include position(absolute, 50%, auto, auto, 50%);
          @include d-flex(center, center, null, null);
          transform: translate(-50%, -50%);
          border-radius: 100%;
          border: none;
          fill: $mineshaft;
          opacity: 0;
          background-color: $secondary;
          transition: all 0.3s ease;

          svg {
            stroke: $white;
          }
        }

        img {
          object-fit: cover;
          height: 8.75rem;
          width: 100%;
          object-position: center 0;
          display: block;
          border-radius: 0.25rem;
          overflow: hidden;

          @include media-breakpoint-up(xs) {
            height: 7.5rem;
          }
        }
      }

    }
  }
}

.active-status {
  border-radius: 2.5rem;
  background-color: $secondary;
  color: $white;
  font-size: 0.875rem;
  padding: 0.188rem 0.5rem;
  display: inline-flex;
  margin-bottom: 1rem;
}

.upload-assets {
  @include d-flex(center, null, null, wrap row);
}

.upload-helptext {
  width: calc(100% - 12.5rem);
}

.upload-preview {
  @include d-flex(null, null, column, null);
  @include size(11.25rem, auto);
  margin-right: 1.25rem;
}

.theme-color-block {
  overflow: hidden;

  h4 {
    margin-bottom: 0.313rem;
  }

  .color-block {
    background-color: $alabaster;
    border: 0.063rem solid $border-color;
    border-radius: $border-radius;
    padding: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;

    .input-holder {
      width: 100%;

      input[type="color"] {
        width: 100%;
        border: none;
        padding: 0;
        height: 2rem;
        border-radius: $border-radius;
        overflow: hidden;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .pick-holder {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .pick-frame {
    width: 100%;
  }

  .btn-link {
    margin-left: 1rem;
    width: 3.438rem;
  }
}

.color-code {
  font-size: 0.75rem;
  margin: 0 0 0.313rem;
  display: inline-block;
}

.check-list {
  @extend %listreset;

  li {
    position: relative;
    margin: 0.313rem 0;

    svg {
      margin-right: 1.25rem;
    }
  }
}

.position-tooltip {
  padding-right: 1.875rem;
  display: inline-block;
  @include media-breakpoint-down(md) {
    max-width: 80%;
  }
  .tool-tip {
    @include position(absolute,0,0,null,null);
  }
}

.edly-go-banner {
  @include d-flex(center, space-between, null, wrap row);
  @include media-breakpoint-down(md) {
    flex-direction: column;
  }

  .img-holder {
    width: 5.75rem;
    border: 0.125rem solid $primary;
    border-radius: $border-radius;
    padding:2rem 0.75rem;
    background-image: url(/assets/images/edly-Go-logo.svg);
    background-repeat: no-repeat;
    background-size: 3.75rem;
    background-position: 50% 50%;
    min-height: 7.5rem;
    margin-top: 1rem;
    @include media-breakpoint-up(md) {
      margin-left: 1.25rem;
      margin-top: 0;
    }
  }

  .text {
    max-width: 50rem;
    width: calc(100% - 7rem);
    @include media-breakpoint-down(md) {
      width: 100%;
      max-width: inherit;
    }
  }

  .btn-holder {
    overflow: hidden;
    margin: 0 -0.625rem 0.625rem;

    button {
      margin: 0 0.625rem;
      padding: 0;
      border: none;
      border-radius: 0;
      background: none;
      max-width: 8.125rem;

      img {
        width: 100%;
        height: auto;
        display: block;
      }
    }
  }
}

.switch-row {
  border-bottom: 1px solid $border-color;
  padding-bottom: 10px;
}

.bar-divider {
  display: flex;
  justify-content: space-between;
  max-width: 70%;
  margin: 0.5rem auto 0.625rem;
  padding: 0.625rem 2% 0 10%;
  font-size: 0.75rem;

  span {
    position: relative;

    &:after {
      content: "";
      background: $alabaster;
      @include size(0.063rem, 0.625rem);
      @include position(absolute, auto, auto, 100%, 50%);
      transform: translateX(-50%);
    }
  }
}

.price-box {
  text-align: center;
  padding: 0;
  margin: 0 0 1.875rem;

  @include media-breakpoint-up(md) {
    padding: 1.875rem;
  }

  .rs-slider-bar,
  .rs-slider-progress-bar {
    height: 0.938rem;
    border-radius: 1.25rem;
  }

  .rs-slider-handle {
    &:before {
      @include size(1.875rem);
    }
  }
}

#learner_download_dropdowns {
  @include d-inline-flex(center, null, null, column);
  @include media-breakpoint-up(sm) {
    @include d-inline-flex(center, null, null, row);
  }
}

.user-guide {
  .demo-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 20px
  }
}

.link {
  color: $secondary;
  text-decoration: underline;
  font-weight: 500;
  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
}

.user-guide {
  z-index: 1;

  .form-cta {
    padding: 0px 2rem !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .demo-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }

    #learner_guide,
    #Instructors_guide {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 0.48;
      border: 1px solid $border-color;
      padding: 1.2rem 2rem;
      border-radius: $border-radius;
      cursor: pointer;
      transition: $transition-base;

      @include media-breakpoint-down(lg) {
        width: 100%;
      }

      &:hover {
        background-color: lighten($secondary, 50%);
        border-color: $secondary;

        svg {
          stroke: $secondary;
        }
      }
    }

    p {
      margin: 0;
    }

    span {
      color: $red;
    }
  }
}

.hide-display {
  display: none !important;
}

.learner-info-container {
  font-size: 0.875rem;
  margin-bottom: 3.125rem;
}

hr {
  border-color: $border-color;
  opacity: 1;
}

.user-actions {
  .dropdown-toggle {
    &:before {
      display: none;
    }
  }
}

.circle-green,
.circle-red,
.circle-orange {
  @include size(7px, 7px);
  border-radius: 100%;
}

.circle-green {
  background-color: $persiangreen;
}

.circle-red {
  background-color: $primary;
}

.circle-orange {
  background-color: $buttercup;
}

.label-green,
.label-red,
.label-orange {
  text-transform: capitalize;
}

.label-green {
  color: $persiangreen; 
}

.label-red {
  color: $primary;
}

.label-orange {
  color: $buttercup;
}

.custom-alert-area {
  width: 100%;
  @include d-flex(center,space-between,row,null);
  @include media-breakpoint-down(xl) {
    flex-direction: column;
    align-items: flex-start;
  }
  @include media-breakpoint-down(md) {
    flex-direction: column;
    align-items: center;
  }
  .text-area {
    @include d-flex(center,null,row,wrap row);
    @include media-breakpoint-down(xl) {
      margin-bottom: 1.25rem;
    }
    @include media-breakpoint-down(md) {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    .text {
      width: calc(100% - 2.5rem);
      flex-direction: column;
      display: flex;
    }
    svg {
      @include size(1.5rem, 1.5rem);
      @include media-breakpoint-down(md) { 
        margin-bottom: 1rem;
      }
    }
  }
}

.switch-billing-banner {
  @include d-flex(center,null,column,null);
  padding: 0.938rem;
  border: 1px solid $persiangreen;
  border-radius: $border-radius;
  background-image: linear-gradient($aqua-spring, $white);
  margin-bottom: 1.875rem;
  p {
    margin-bottom: 0;
  }
  h5 {
    margin-bottom: 0.313rem;
    span {
      color: $persiangreen;
    }
  }
}

.roles-frame {
  padding: 0 0.938rem;
  .user-role-list {
    @extend %listreset;
    font-size: 0.875rem;
    margin-bottom: 0.313rem;
    li {
      text-align: left;
      .custom-control {
        justify-content: flex-start;
        .custom-control-input {
          margin-right: 1rem;
        }
      }
    }
  }
}

.search-roles {
  padding: 0 0.938rem;
  .roles-frame {
    padding: 0;
  }
  .user-role-list {
    @extend %listreset;
    margin-bottom: 0.938rem;
    font-size: 0.875rem;
    li {
      text-align: left;
      .custom-control {
        justify-content: flex-start;
        .custom-control-input {
          margin-right: 1rem;
        }
      }
    }
  }
}

.user-filter-holder {
  @include media-breakpoint-down(xxl) {
    z-index: 2;
    .navbar-collapse {
      @include position(absolute,100%,null,null,0);
      background-color: $white;
      box-shadow: $box-shadow;
      z-index: 2;
      padding: 1.25rem;
      transition: none;
      border-radius: $border-radius;
      border: 1px solid $border-color;
    }
    .navbar-nav {
      .nav-link {
        padding: 0;
        margin: 0;
      }
    }
    .user-filters {
      .dropdown-menu {
        @include position(absolute,100%,null,null,0);
      }
    }
  }
  @include media-breakpoint-down(md) {
    .navbar-collapse {
      @include position(absolute,100%,0,null,auto);
    }
    .user-filters {
      .dropdown-menu {
        @include position(absolute,100%,0,null,auto);
      }
    }
  }
}

.user-filters {
  .dropdown-menu {
    position: relative;
    min-width: 12.5rem;
  }
  .maus-filter-footer {
    @include d-flex(center,space-between,null,null);
    padding: 0 0.938rem 0.938rem;
    .btn {
      width: 50%;
    }
  }
}

.courses-analytics {
  .course-details {
    border: 1px solid $border-color;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    background-color: $white;
    width: 100%;
    padding: 1.25rem;
    margin: 0 0 1.25rem;
    color: $black;
    @include media-breakpoint-up(xl) {
      display: table;
    }

    > * {
      @include media-breakpoint-up(xl) {
        display: table-cell;
        vertical-align: middle;
      }
    }

    .course-meta {
      width: 100%;
      font-size: 0.813rem;
      margin-bottom: 1.25rem;
      @include media-breakpoint-up(xl) {
        width: 30%;
        margin-bottom: 0;
      }
      @include media-breakpoint-up(xxl) {
        width: 46%;
        margin-bottom: 0;
      }
      @include media-breakpoint-down(md) {
        text-align: center;
      }

      .course-code {
        font-size: 0.75rem;  
        display: block;
      }

      .course-name {
        font-weight: 500;
        font-size: 1rem;
        display: inline-block;
        margin: 0.313rem 0 0.5rem;
        color: $secondary;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .course-analytics-values {
      width: 100%;
      text-align: center;
      border-top: 1px solid $border-color;
      border-left: none;
      padding-top: 1.25rem;
      @include media-breakpoint-up(xl) {
        padding-top: 0;
        width: 70%;
        border-left: 1px solid $border-color;
        border-top: none;
      }
      @include media-breakpoint-up(xxl) {
        width: 54%;
        border-left: 1px solid $border-color;
      }

      > div {
        display: inline-block;
        vertical-align: top;
        text-align: center;
        padding: 0 0.938rem;
        font-weight: 500;
        font-size: 1.125rem;
        margin-bottom: -0.25rem;
        width: 100%;
        @include media-breakpoint-up(md) {
          width: 20%;
        }
        @include media-breakpoint-up(xl) {
          width: auto;
        }

        span {
          display: block;
          font-size: 0.75rem;
          padding-bottom: 0.5rem;
          margin-bottom: 0.5rem;;
          font-weight: normal;
          border-bottom: 0.063rem solid $border-color;
        }

        &:first-child {
          @include media-breakpoint-up(xl) {
            padding-left: 0;
          }
        }

        &:last-child {
          @include media-breakpoint-up(xl) {
            padding-right: 0;
          }
        }
      }
    }
  }

  .course-instructors,
  .course-dated {
    display: block;

    span {
      display: inline-block;
      vertical-align: middle;
      margin-right: 0.313rem;
    }
  }
}

.learner-analytics-stats {
  .card {
    height: 100%;
  }
}

.color-secondary {
  color: $secondary;
}

.loader {
  @include position(absolute,50%,null,null,1.25rem);
  @include size(0.875rem, 0.875rem);
  border: 0.125rem solid $white;
  border-top: 0.125rem solid $gray-600;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.dashboard-course-stats {
  .card,
  .stats-box {
    @include media-breakpoint-up(lg) {
      height: 100%;
    }
  }
}

.management-table-holder {
  td.management-table-frame {
    position: relative;
    height: 6.25rem;
    .loading-dots {
      top: 1.25rem;
    }
  }
}

.text-ellipsis {
  max-width: 11.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dns-card {
  min-width: 18.75rem;
  padding: 1.25rem 1.95rem 0.625rem;
  position: relative;
  display: inline-block;
  border-bottom: 0.063rem solid $border-color;
  &:after, 
  &:before {
    content: "";
    background: $border-color;
    @include position(absolute,null,0,0,auto);
    @include size(0.063rem, 1.875rem);
  }
  &:before {
    @include position(absolute,null,null,0,0);
  }
  .btn-holder {
    text-align: right;
    padding-top: 0.625rem;
  }
  .url-details {
    font-size: 0.875rem;
    display: flex;
    align-items: flex-start;
    margin: 0.313rem 0;
  }
}

.reset-holder {
  .reset-text {
    width: 65% !important;
    display: inline-block;
  }
  .reset-button {
    float: right;
  }
}

.plan-banner {
  padding: 0 0.938rem;
  @include media-breakpoint-up(md) {
    padding: 0 1.875rem;
  }
  @include media-breakpoint-up(xl) {
    padding: 0 3.125rem;
  }
  .plan-banner-holder {
    max-width: 1440px;
    margin: 0 auto;
  }
  .alert {
    margin-bottom: 0;
    margin-top: 1.875rem;
    @include media-breakpoint-up(md) {
      margin-top: 1.875rem;
    }
    @include media-breakpoint-up(xl) {
      margin-top: 1.875rem;
    }
    @include d-flex(center,space-between,null,null);
    @include media-breakpoint-down(lg) {
      flex-direction: column;
    }
  }
}

.text-error {
  color: $primary;
}

.contract-content {
  font-size: 0.875rem;
  p {
    font-size: 0.875rem;
  }
  ul {
    @extend %listreset;
    margin: 0.938rem 0;
    > li {
      position: relative;
      padding-left: 0.938rem;
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0;
      }
      &:after {
        content: "";
        @include size(5px);
        @include position(absolute,10px,null,null,0);
        border-radius: 100%;
        background-color: $gray-600;
      }
    }
  }
  ol {
    @extend %listreset;
    list-style: none;
    counter-reset: item;
    &:last-child {
      margin-bottom: 0;
    }
    > li {
      counter-increment: item;
      margin-bottom: 5px;
      position: relative;
      padding-left: 1.875rem;
      &:before {
        @include size(1.25rem, 1.25rem);
        @include d-inline-flex(center,center,null,null);
        @include position(absolute,0,null,null,0);
        margin-right: 10px;
        content: counter(item);
        background-color: $gray-100;
        border-radius: 100%;
        text-align: center;
        font-size: 0.688rem;
      }
    }
  }
}

.card-area {
  border: 0.063rem solid $white;
  box-shadow: $box-shadow;
  border-radius: $border-radius;
  background: rgba(255, 255, 255, 0.50);
}

.card-holder {
  @include d-flex(null,null,null,null);
  @include media-breakpoint-down (md) {
    @include d-flex(center,null,null,column);
  }
  .card-title {
    @include d-flex(center,null,null,null);
  }
}

.payment-method-holder {
  .card {
    min-height: 9.375rem;
    @include media-breakpoint-up(lg) {
      height: 100%;
    }
    @include media-breakpoint-down(md) {
      text-align: center;
    }
  }
}

.addons-holder {
  
  @include media-breakpoint-up(md) {
    border-radius: $border-radius;
    background-color: $alabaster;
    padding: 1.875rem 3.125rem;
  }
  .slick-prev {
    left: -2.5rem;
  }
  .slick-next {
    right: -2.5rem;
  }
  .slick-track {
    display: flex !important;
  }
  .slick-list {
    margin: 0 -0.75rem;
  }
  .slick-slide {
    padding: 0 0.75rem 0.313rem;
    height: inherit !important;
    .card {
      position: relative;
    }
  }
}

.paid-label {
  border-radius: 1.25rem;
  padding: 0.313rem 0.625rem;
  color: $secondary;
  border: 1px solid $secondary;
  display: inline-block;
  margin-bottom: 0.625rem;
  font-size: 0.75rem;
}

.addon-card {
  padding-bottom: 3.75rem;
}

.summation-holder {
  background-color: $selago;
  border-radius: $border-radius;
}

.site-details-area {
  width: 100%;
  @include media-breakpoint-up(xl) {
    width: calc(100% - 400px);
  }
}

.billing-info-area {
  width: 100%;
  margin: 30px 0;
  @include media-breakpoint-up(sm) {
    width: 370px;
    margin: 30px auto;
  }
  @include media-breakpoint-up(xl) {
    width: 370px;
    margin: 0 auto 0 30px;
  }
}

.billing-info-major-indicator {
  background-color: $persiangreen;
  width: 12px;
  height: 4px;
  margin-right: 8px;
}

.billing-info-minor-indicator {
  background-color: $bermuda;
  width: 12px;
  height: 4px;
  margin-right: 8px;
}
